@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Inter", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", serif;
}
p,
a {
  font-family: "Poppins", serif;
}

@layer components {
  .text_primary {
    @apply text-violet-500 hover:text-white hover:bg-violet-500 transition-all rounded;
  }
  .primary_color {
    @apply text-violet-500;
  }
  .secondary_color {
    @apply text-white;
  }
  .primary_hover {
    @apply text-violet-500;
  }
  .secondary_hover {
    @apply text-white;
  }
  
  
  .btn-primary {
    @apply py-2 px-5 bg-gradient-to-r from-pink-300 to-purple-500 text-white font-semibold rounded-full shadow-md hover:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;
  }

  .all-centered {
    @apply flex items-center justify-center;
  }

  .gradient-heading {
    @apply bg-gradient-to-r from-purple-300 to-purple-500 bg-clip-text text-transparent text-3xl tracking-tight;
  }
  .pageTitle {
    @apply bg-violet-500 text-white text-3xl text-center rounded capitalize p-3;
  }
  .gradient-text {
    @apply;
  }

  .section-heading {
    @apply my-20 text-center text-4xl font-semibold;
  }

  .section-para {
    @apply my-2 max-w-xl py-6 text-xl tracking-tighter;
  }

  .btn {
    @apply w-auto min-w-20 p-2 border-none rounded-md text-xl text-center mx-auto;
  }

  .btn-orange {
    @apply bg-amber-500 text-white hover:bg-amber-600;
  }

  .btn-green {
    @apply bg-[#1DD881] text-white hover:bg-[#1dd881e0];
  }
}
